import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import { useLightMode } from "../ducks/colourModeSlice";
import { useUpdateHeaderActions } from "../ducks/headerActionsSlice";
import { useAuthRedirects, loginPrimary } from "../ducks/loginSlice";
import { TransitionContext } from "../layouts/transition";
import useWindowWidth from "../hooks/useWindowWidth";
import {
  initialFormValues,
  FormField,
  Checkbox,
  Error,
  changeFormValue,
  submitForm,
  anyValueEmpty,
} from "../utils/formHelpers";
import { H3, Body, Detail } from "../components/common/typography/";
import CTAButton from "../components/common/cta-button";
import { ExternalLink } from "../components/common/links";
import SEO from "../components/common/seo";
import YearSealLight from "../components/images/YearSealLight";
import fullBottleLight from "../assets/images/FullBottleLight.png";

const EnterPage = () => {
  const redirected = useAuthRedirects();
  const transitionStatus = useContext(TransitionContext);
  useLightMode();
  useUpdateHeaderActions(
    {
      left: {
        text: "See Whisky",
        to: "/",
      },
      right: {
        text: "Log In",
        to: "/login",
      },
    },
    redirected || transitionStatus !== "entered"
  );

  const dispatch = useDispatch();
  const [formIsSaving, setFormIsSaving] = React.useState(false);
  const [fetchError, setFetchError] = React.useState(null);
  const [formValues, setFormValues] = React.useState(initialFormValues);
  const [ageCheckbox, setAgeCheckbox] = React.useState(false);

  const onSubmit = () =>
    submitForm({
      functionUrl: "/.netlify/functions/newBallot",
      successCallback: (response) => {
        navigate("/share");
        dispatch(
          loginPrimary({
            code: response.code,
            email: response.email,
            fname: response.fname,
            friendCount: response.friendCount,
          })
        );
      },
      formValues,
      setFormValues,
      setFetchError,
      setFormIsSaving,
    });

  const theme = useContext(ThemeContext);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth && windowWidth <= theme.maxMobileSize;

  return (
    <Root>
      <SEO title="Enter the Ballot" />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <H3>Sign up and share</H3>
        <Tagline>
          To be in with a chance of winning this bottle, sign up below. You'll
          receive a link that you can share with four other friends or family to
          complete your entry.
        </Tagline>
        {formValues.map((field) => (
          <FormField
            key={field.key}
            field={field}
            onChange={changeFormValue(field.key, setFormValues, setFetchError)}
          />
        ))}
        <Checkbox
          name="checkbox"
          label={
            <span>
              I confirm that I am of legal drinking age in my country and agree
              to the{" "}
              <ExternalLink
                href="https://www.glengoyne.com/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms &amp; Conditions
              </ExternalLink>
            </span>
          }
          checked={ageCheckbox}
          onChange={() => setAgeCheckbox((checked) => !checked)}
        />
        <StyledError content={fetchError}>{fetchError}</StyledError>
        <CTAButton
          inverted
          spaced
          text={formIsSaving ? "Saving..." : "Enter Ballot"}
          action={onSubmit}
          fullWidth={isMobile}
          disabled={!ageCheckbox || anyValueEmpty(formValues) || formIsSaving}
        />
        <Agreement>
          By signing up with your email, you agree to receive updates about
          Glengoyne Highland Single Malt.
        </Agreement>
      </Form>
      <SealWrapper>
        <YearSealLight />
      </SealWrapper>
    </Root>
  );
};

const Root = styled.div`
  padding-top: 146px;
  min-height: 100vh;
  width: 100%;
  background-image: url(${fullBottleLight});
  text-align: left;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 3fr;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    text-align: center;
    background: none;
    display: block;
  }
`;

const Form = styled.form`
  padding: ${({ theme }) => theme.spacing.xxl};
  padding-left: ${({ theme }) => theme.spacing.mega};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: ${({ theme }) => theme.spacing.md};
    align-items: center;
  }
`;

const StyledError = styled(Error)`
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

const Tagline = styled(Body)`
  margin: ${({ theme }) => `${theme.spacing.xxs} 0 ${theme.spacing.xl} 0`};
`;

const Agreement = styled(Detail)`
  max-width: 300px;
`;

const SealWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.xxl};
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    display: none;
  }
`;

export default EnterPage;
